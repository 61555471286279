import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Customers from '../views/Customers.vue'
import Documents from '../views/Documents.vue'
import firebase from '@/firebaseinit.js'
import { getAuth, onAuthStateChanged } from "firebase/auth";

const auth = getAuth(firebase);

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/ugyfelek',
    name: 'Customers',
    component: Customers
  },
  {
    path: '/nyomtatvanyok',
    name: 'Documents',
    component: Documents
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  await onAuthStateChanged(auth, (user) => {
    if (to.path !== '/' && (!user && to.path !== '/login')) next('login')
    else next()
  });
})

export default router
