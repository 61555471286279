<template lang="html">
  <div class="documents-component">
    <br>
    <v-container>
    <v-card>
      <v-card-title>
        Meghatalmazás készítése
      </v-card-title>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
        >
        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="meghatalmazo"
              :items="customers"
              item-text="name"
              return-object
              label="Meghatalmazó"
              :rules="required"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="meghatalmazott"
              :items="customers"
              item-text="name"
              return-object
              label="Meghatalmazott"
              :rules="required"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="targy"
              label="Meghatalmazás tárgya"
              :rules="required"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="tanu1"
              :items="customers"
              item-text="name"
              return-object
              label="Tanú 1."
              :rules="required"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
              v-model="tanu2"
              :items="customers"
              item-text="name"
              return-object
              label="Tanú 2."
              :rules="required"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="kelt"
              label="Keltezés"
              :rules="required"
            ></v-text-field>
          </v-col>
        </v-row>
        </v-form>
        <br>
        <v-alert
          v-if="!valid"
          dense
          outlined
          type="error"
        >
          Minden mező kitöltése kötelező!
        </v-alert>
      </v-container>
      <v-card-actions>
        <v-row>
          <v-col cols="6" md="2">
          <v-btn
          color="red"
          outlined
          @click="Print()"
          :disabled="!valid || print_loader"
          :loading="print_loader"
          >
            Nyomtatás
            <v-icon
              right
              dark
            >
              print
            </v-icon>
          </v-btn>
          </v-col>
        <v-col cols="6" md="2">
        <v-btn
        color="red"
        outlined
        :disabled="!valid || downloader"
        :loading="downloader"
        @click="Download()"
        >
          Letöltés
          <v-icon
            right
            dark
          >
            file_download
          </v-icon>
        </v-btn>
        </v-col>
        <v-col cols="6" md="2">
        <v-btn
          color="red"
          outlined
          :disabled="!valid"
          @click="send_dialog = true"
        >
          Küldés
          <v-icon
            right
            dark
          >
            mail_outline
          </v-icon>
        </v-btn>
        </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    </v-container>

    <v-dialog
      v-model="send_dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Meghatalmazás elküldése</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
              ref="email_form"
              v-model="email_valid"
            >
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="email_name"
                  label="Címzett neve"
                  :rules="required"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="email"
                  label="E-mail címe"
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
            </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            outlined
            @click="send_dialog = false"
          >
            Mégsem
          </v-btn>
          <v-btn
            color="red"
            outlined
            @click="Send()"
            :disabled="!email_valid || mail_loader"
            :loading="mail_loader"
          >
            Küldés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="error_snackbar"
      color="red"
    >
      {{ error_message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="error_snackbar = false"
        >
          bezár
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="success_snackbar"
    >
      {{ success_message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="success_snackbar = false"
        >
          bezár
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
import EventBus from '@/EventBus';
import firebase from '@/firebaseinit';
import { getAuth } from "firebase/auth";
import { getFirestore, collection, getDocs, getDoc, query, where, doc} from "firebase/firestore"
const db = getFirestore(firebase);
const auth = getAuth(firebase);

  export default {
    data: () => ({
      uid: '',
      customers: [],
      meghatalmazo: [],
      meghatalmazott: [],
      tanu1: [],
      tanu2: [],
      targy: '',
      kelt: '',
      valid: true,
      email_valid: true,
      required: [
        v => !!v || 'A mező kitöltése kötelező!'
      ],
      emailRules: [
        v => !!v || 'A mező kitöltése kötelező!',
        v => /.+@.+\..+/.test(v) || 'Az e-mail cím formátuma nem megfelelő!',
      ],
      print_loader: false,
      downloader: false,
      jwt: '',
      send_dialog: false,
      email_name: '',
      email: '',
      mail_loader: false,
      error_snackbar: false,
      success_snackbar: false,
      error_message: '',
      success_message: '',
      date: new Date().toISOString().substr(0, 10),
      city: '',
    }),
    methods: {
      getUid: async function() {
        const user = await auth.currentUser;
        this.uid = user.uid;
        this.jwt = user.stsTokenManager.accessToken;
        console.log(user);
      },
      getCustomers: async function () {
        this.customers = [];
        await this.getUid();
        const q = query(collection(db, "customers"), where("uid", "==", this.uid));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          let datas = { ...doc.data(), id: doc.id};
          this.customers.push(datas);
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
        });
        console.log(this.customers);
      },
      fetchCustomerDatas: async function () {
        // MEGHATALMAZÓ ADATAI
        const meghatalmazo_id = this.meghatalmazo.id;
        const meghatalmazoRef = doc(db, "customers", meghatalmazo_id);
        const meghatalmazoSnap = await getDoc(meghatalmazoRef);
        if (meghatalmazoSnap.exists()) {
          this.meghatalmazo = { ...meghatalmazoSnap.data(), id: meghatalmazo_id};
          console.log(this.meghatalmazo);
        } else {
          console.log("No such document!");
        }
        // MEGHATALMAZOTT ADATAI
        const meghatalmazott_id = this.meghatalmazott.id;
        const meghatalmazottRef = doc(db, "customers", meghatalmazott_id);
        const meghatalmazottSnap = await getDoc(meghatalmazottRef);
        if (meghatalmazottSnap.exists()) {
          this.meghatalmazott = { ...meghatalmazottSnap.data(), id: meghatalmazott_id};
          console.log(this.meghatalmazott);
        } else {
          console.log("No such document!");
        }
        // TANÚ1 ADATAI
        const tanu1_id = this.tanu1.id;
        const tanu1Ref = doc(db, "customers", tanu1_id);
        const tanu1Snap = await getDoc(tanu1Ref);
        if (tanu1Snap.exists()) {
          this.tanu1 = { ...tanu1Snap.data(), id: tanu1_id};
          console.log(this.tanu1);
        } else {
          console.log("No such document!");
        }
        // TANÚ2 ADATAI
        const tanu2_id = this.tanu2.id;
        const tanu2Ref = doc(db, "customers", tanu2_id);
        const tanu2Snap = await getDoc(tanu2Ref);
        if (tanu2Snap.exists()) {
          this.tanu2 = { ...tanu2Snap.data(), id: tanu2_id};
          console.log(this.tanu2);
        } else {
          console.log("No such document!");
        }
      },
      Print: async function() {
        await this.getUid();
        await this.fetchCustomerDatas();
        this.print_loader = true;
        this.$http.post('/meghatalmazas', {
        jwt: this.jwt,
        meghatalmazo: this.meghatalmazo,
        meghatalmazott: this.meghatalmazott,
        tanu1: this.tanu1,
        tanu2: this.tanu2,
        targy: this.targy,
        kelt: this.kelt,
        mail: 'false'
        }, {responseType: 'blob'})
        .then((response) => {
          this.print_loader = false;
          const file = new Blob(
          [response.data],
          {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      Download() {
        this.downloader = true;
        this.$http.post('/meghatalmazas', {
        jwt: this.jwt,
        meghatalmazo: this.meghatalmazo,
        meghatalmazott: this.meghatalmazott,
        tanu1: this.tanu1,
        tanu2: this.tanu2,
        targy: this.targy,
        kelt: this.kelt,
        mail: 'false'
        }, {responseType: 'blob'})
        .then((response) => {
          this.downloader = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'meghatalmazás_'+this.meghatalmazott['name']+'.pdf');
          document.body.appendChild(link);
          link.click();
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      Send() {
        this.mail_loader = true;
        this.$http.post('/send_mail', {
        jwt: this.jwt,
        meghatalmazo: this.meghatalmazo,
        meghatalmazott: this.meghatalmazott,
        tanu1: this.tanu1,
        tanu2: this.tanu2,
        targy: this.targy,
        kelt: this.kelt,
        mail: 'true',
        email_name: this.email_name,
        email: this.email
        })
        .then((response) => {
          this.mail_loader = false;
          this.send_dialog = false;
          if (response.data['success'] == true) {
            this.success_message = response.data['message'];
            this.success_snackbar = true;
          } else {
            this.error_message = response.data['message'];
            this.error_snackbar = true;
          }
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      cityService() {
        this.$http.get('/city')
        .then((response) => {
          this.city = response.data;
          this.kelt = this.city+", "+this.date;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
    },
    mounted() {
      this.getCustomers()
      this.cityService()
    },
    created() {
      EventBus.$on('refreshCustomers', () => {
            this.getCustomers();
      })
    },
  }
</script>

<style lang="css">
</style>
