import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBC6fikbH9WELNld1Anlje8oTLWTIjrrbI",
  authDomain: "fir-app-e9f7f.firebaseapp.com",
  projectId: "fir-app-e9f7f",
  storageBucket: "fir-app-e9f7f.appspot.com",
  messagingSenderId: "697636169740",
  appId: "1:697636169740:web:f023fd6cdecaab98b06629",
  measurementId: "G-P31C2ZD6JC"
};

const firebase = initializeApp(firebaseConfig);

export default firebase;
