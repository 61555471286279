<template lang="html">
  <div class="customer-component text-center">

   <v-container>
    <br>
    <v-alert
      text
      prominent
      type="error"
      icon="report_gmailerrorred"
    >
      A rögzített személyes adatok az Ön bejelentkezéséhez kötődnek, ahhoz más illetéktelenül nem férhet hozzá!
    </v-alert>
    <br>
    <v-card>
      <v-card-title>
        Ügyfelek
      </v-card-title>
      <v-container>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Keresés"
        outlined
      ></v-text-field>
      </v-container>
      <v-data-table
        :headers="headers"
        :items="customers"
        :search="search"
        @click:row="getCustomer($event.id)"
      ></v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          outlined
          @click="newCustomer()"
        >
          Hozzáadás
          <v-icon
            right
            dark
          >
            add_circle_outline
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
   </v-container>

  <v-row justify="center">
    <v-dialog
      v-model="new_customer_dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Ügyfél rögzítése</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div v-if="preloader">
              <v-progress-linear
                indeterminate
                color="red"
              ></v-progress-linear>
            </div>
            <div v-if="!preloader">
            <v-form
              ref="form"
              v-model="valid"
            >
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="name"
                  label="Név"
                  :rules="required"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="birt_place"
                  label="Születési hely"
                  :rules="required"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="birthdate"
                  label="Születési idő"
                  v-mask="'####.##.##'"
                  :rules="required"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="mother_name"
                  label="Anyja neve"
                  :rules="required"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
              <v-text-field
                v-model="id_card"
                label="Személyi igazolvány száma"
                :rules="required"
              ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="address"
                  label="Lakcím"
                  :rules="required"
                ></v-text-field>
              </v-col>
            </v-row>
            </v-form>
            </div>
          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="mod_id"
            color="red"
            outlined
            @click="del_dialog = true"
          >
            Töröl
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            outlined
            @click="new_customer_dialog = false"
          >
            Bezár
          </v-btn>
          <v-btn
            v-if="!mod_id"
            color="red"
            outlined
            @click="saveCustomer()"
            :disabled="!valid"
          >
            Rögzít
          </v-btn>
          <v-btn
            v-if="mod_id"
            color="red"
            outlined
            @click="modCustomer()"
            :disabled="!valid"
          >
            Módosít
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

  <v-dialog
    v-model="del_dialog"
    persistent
    max-width="290"
  >
    <v-card>
      <v-card-title>
        Ügyfél törlése
      </v-card-title>
      <v-card-text>Biztosan törölni szeretné az ügyfelet? ({{name}})</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green"
          text
          @click="del_dialog = false"
        >
          Nem
        </v-btn>
        <v-btn
          color="red"
          text
          @click="delCustomer(), del_dialog = false"
        >
          Igen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-snackbar
    v-model="error_snackbar"
    color="red"
  >
    {{ error_message }}

    <template v-slot:action="{ attrs }">
      <v-btn
        color="white"
        text
        v-bind="attrs"
        @click="error_snackbar = false"
      >
        bezár
      </v-btn>
    </template>
  </v-snackbar>

  <v-snackbar
    v-model="success_snackbar"
  >
    {{ success_message }}

    <template v-slot:action="{ attrs }">
      <v-btn
        color="pink"
        text
        v-bind="attrs"
        @click="success_snackbar = false"
      >
        bezár
      </v-btn>
    </template>
  </v-snackbar>

  </div>
</template>

<script>
import firebase from '@/firebaseinit';
import { getAuth } from "firebase/auth";
import { getFirestore, collection, addDoc, getDocs, getDoc, query, where, doc, updateDoc, deleteDoc } from "firebase/firestore"
const db = getFirestore(firebase);
const auth = getAuth(firebase);

  export default {
    data: () => ({
        search: '',
        customers: [],
        headers: [
          { text: 'Név', value: 'name' },
          { text: 'Születési idő', value: 'birthdate' },
          { text: 'Anyja neve', value: 'mother_name' },
          { text: 'Személyi igazolvány', value: 'id_card' },
          { text: 'Lakcím', value: 'address' },
        ],
        new_customer_dialog: false,
        name: '',
        birthdate: '',
        birt_place: '',
        id_card: '',
        mother_name: '',
        address: '',
        uid: '',
        mod_id: '',
        error_snackbar: false,
        success_snackbar: false,
        error_message: '',
        success_message: '',
        valid: true,
        required: [
          v => !!v || 'A mező kitöltése kötelező!'
        ],
        preloader: false,
        del_dialog: false,
    }),
    methods: {

      getUid: async function() {
        const user = await auth.currentUser;
        this.uid = user.uid;
      },
      newCustomer() {
        this.new_customer_dialog = true;
        this.resetValidation()
        this.name = '';
        this.birthdate = '';
        this.birt_place = '';
        this.mother_name = '';
        this.id_card = '';
        this.address = '';
        this.mod_id = '';
      },
      saveCustomer: async function () {
        await this.getUid();
        try {
          const docRef = await addDoc(collection(db, "customers"), {
            name: this.name,
            birthdate: this.birthdate,
            birt_place: this.birt_place,
            mother_name: this.mother_name,
            id_card: this.id_card,
            address: this.address,
            uid: this.uid
          });
          this.getCustomers()
          this.success_snackbar = true;
          this.success_message = 'Sikeres rögzítés!';
          this.new_customer_dialog = false;
          console.log("Document written with ID: ", docRef.id);
        } catch (e) {
          console.error("Error adding document: ", e);
          this.error_snackbar = true;
          this.error_message = 'Rögzítés sikertelen!';
        }
      },
      getCustomers: async function () {
        this.customers = [];
        await this.getUid();
        const q = query(collection(db, "customers"), where("uid", "==", this.uid));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          let datas = { ...doc.data(), id: doc.id};
          this.customers.push(datas);
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
        });
        //console.log(this.customers);
      },
      getCustomer: async function (id) {
        this.preloader = true;
        this.new_customer_dialog = true;
        const docRef = doc(db, "customers", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          this.preloader = false;
          //this.new_customer_dialog = true;
          this.name = docSnap.data().name;
          this.birthdate = docSnap.data().birthdate;
          this.birt_place = docSnap.data().birt_place;
          this.mother_name = docSnap.data().mother_name;
          this.id_card = docSnap.data().id_card;
          this.address = docSnap.data().address;
          this.mod_id = id;
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      },
      modCustomer: async function () {
        try {
          const docRef = doc(db, "customers", this.mod_id);
          await updateDoc(docRef, {
            name: this.name,
            birthdate: this.birthdate,
            birt_place: this.birt_place,
            mother_name: this.mother_name,
            id_card: this.id_card,
            address: this.address
          });
          this.getCustomers()
          this.success_snackbar = true;
          this.success_message = 'Sikeres rögzítés!';
          this.new_customer_dialog = false;
        } catch (e) {
          console.error("Error adding document: ", e);
          this.error_snackbar = true;
          this.error_message = 'Rögzítés sikertelen!';
        }
      },
      delCustomer: async function () {
        try {
          await deleteDoc(doc(db, "customers", this.mod_id));
          this.getCustomers()
          this.success_snackbar = true;
          this.success_message = 'Sikeres törlés!';
          this.new_customer_dialog = false;
        } catch (e) {
          console.error("Error adding document: ", e);
          this.error_snackbar = true;
          this.error_message = 'Törlés sikertelen!';
        }
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
    mounted() {
      this.getCustomers()
    }
  }
</script>

<style lang="css">
</style>
