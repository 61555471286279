<template>
  <div class="home">
    <br>
    <v-container>
      <v-card outlined shaped color="orange lighten-5">
        <v-card-title>
          <span class="text-h5">Üdvözöljük!</span>
        </v-card-title>
      <v-container>
      <p>Az alkalmazás kipróbálásával lehetősége nyílik betekintést nyerni az általunk készített programok kinézetébe, illetve a legalapvetőbb funkciók működésébe:</p>
      <ul>
        <li>bejelentkezési folyamatok;</li>
        <li>adatrögzítés, módosítás, törlés;</li>
        <li>dokumentum generálás;</li>
        <li>e-mail küldés;</li>
      </ul>
      <br>
      <p>A Demo Alkalmazással egy jogszabályoknak megfelelő meghatalmazást tud generálni, amit felhasználhat bármilyen hivatalos ügyintézéshez.</p>
      </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  }
}
</script>
