<template>
  <v-app>
    <v-app-bar
      app
      color="rgba(0,0,0,0.9)"
      dark
    >
    <router-link to="/">
      <div class="d-flex align-center">
        <v-img
          alt="Csigavér Webfejlesztés"
          class="shrink mr-2"
          contain
          src="https://szoftver-fejlesztes.csigaver.hu/wp-content/uploads/2021/03/snail.png"
          transition="scale-transition"
          width="60"
        />

      </div>
    </router-link>

      <v-spacer></v-spacer>

      <v-toolbar-title>Demo Alkalmazás</v-toolbar-title>

      <v-spacer></v-spacer>

      <div class="hidden-sm-and-down">
        <v-btn text to="/ugyfelek">Ügyfelek<v-icon right>account_circle</v-icon></v-btn>
        <v-btn text to="/nyomtatvanyok" @click="refreshCustomers()">Nyomtatványok<v-icon right>edit_note</v-icon></v-btn>
        <v-btn text to="/login">Felhasználó<v-icon right>power_settings_new</v-icon></v-btn>
      </div>

      <div class="hidden-md-and-up">
        <v-btn @click.stop="drawer = !drawer">
          <v-icon>menu</v-icon>
        </v-btn>
      </div>

    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      temporary
      right
      app
      dark
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Főmenü</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>

        <v-list-item
          link
          to="/ugyfelek"
        >
          <v-list-item-icon>
            <v-icon>account_circle</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Ügyfelek</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/nyomtatvanyok"
          @click="refreshCustomers()"
        >
          <v-list-item-icon>
            <v-icon>edit_note</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Nyomtatványok</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/login"
        >
          <v-list-item-icon>
            <v-icon>power_settings_new</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Felhasználó</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </v-main>

  </v-app>
</template>

<script>
import EventBus from '@/EventBus';

export default {
  name: 'App',

  components: {
  },

  data: () => ({
      drawer: null,
  }),
  methods: {
    refreshCustomers() {
      EventBus.$emit('refreshCustomers')
    },
  }
};
</script>

<style>

</style>
