<template>
  <div class="login text-center">
    <v-container>
      <h1>Bejelentkező oldal</h1>

      <div v-if="!user">
      <br>
      <v-card
        class="mx-auto"
        max-width="344"
      >
        <v-card-text>
          <v-form
            ref="login_form"
            v-model="valid"
          >
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="login_user"
              label="Felhasználónév"
              outlined
              :rules="emailRules"
              required
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="password"
              label="Jelszó"
              outlined
              :rules="passRules"
              required
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              counter
              @click:append="show1 = !show1"
            ></v-text-field>
          </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="ma-2"
            outlined
            color="indigo"
            :disabled="!valid || singinLoading"
            :loading="singinLoading"
            @click="Login()"
          >
            Bejelentkezés
          </v-btn>
          <v-btn
            class="ma-2"
            outlined
            color="red"
            :disabled="!valid || regButtonLoading"
            :loading="regButtonLoading"
            @click="Register()"
          >
            Regisztráció
          </v-btn>
        </v-card-actions>
      </v-card>

      <br>
      <v-row justify="center">
      <v-col
        cols="12"
        md="4"
      >
      <v-alert
        border="left"
        colored-border
        color="red"
        outlined
      >
        Az alkalmazás kipróbálásához nem szükséges valós e-mail cím megadása (regisztrálása),
        bármilyen fiktív e-mail is megadható, sőt választhat "név nélküli" bejelentkezési módot is!
      </v-alert>
      </v-col>
      </v-row>

      <v-btn
        v-if="!user"
        class="ma-2"
        outlined
        color="indigo"
        @click="dialog = true"
        :loading="abLoading"
        :disabled="abLoading"
      >
        Bejelentkezés név nélkül
      </v-btn>
      </div>

      <div v-if="user">
        <br>
        <v-card
          class="mx-auto"
          max-width="344"
        >
        <h2>Bejelentkezve, mint:</h2>
        <h3>{{username}}</h3>
        <br>
      <v-btn
        v-if="user"
        class="ma-2"
        outlined
        color="red"
        @click="Logout()"
      >
        Kilépés
      </v-btn>
      </v-card>
      </div>

    </v-container>

    <v-dialog
    v-model="dialog"
    width="500"
    persistent
    >

      <v-card>
        <v-card-title class="text-h5 red lighten-2">
          Figyelem!
        </v-card-title>

        <v-card-text>
          <br>
          Hogyha ezt a bejelentkezési módot választja, a rendszerben rögzített teszt adatokat csak addig éri el, amíg ki nem jelentkezik, ezt követően elvesznek!
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            @click="dialog = false"
          >
            Mégsem
          </v-btn>
          <v-btn
            color="green"
            outlined
            @click="dialog = false, AnonymousLogin()"
          >
            Rendben
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="error_snackbar"
      color="red"
    >
      {{ error_message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="error_snackbar = false"
        >
          bezár
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="success_snackbar"
    >
      {{ success_message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="success_snackbar = false"
        >
          bezár
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
import firebase from '@/firebaseinit';
import { getAuth, signInAnonymously, signOut, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
const auth = getAuth(firebase);

export default {
  name: 'Login',
  components: {
  },
  data: () => ({
      drawer: null,
      user: '',
      username: '',
      login_user: '',
      password: '',
      dialog: false,
      valid: true,
      emailRules: [
        v => !!v || 'Kötelező kitölteni!',
        v => /.+@.+\..+/.test(v) || 'A felhasználónevet e-mail cím formában kell megadni (nem kell valósnak lennie)!',
      ],
      passRules: [
        v => !!v || 'Kötelező kitölteni!',
        v => (v && v.length >= 6) || 'Legalább 6 karakter!',
      ],
      error_snackbar: false,
      error_message: '',
      success_message: '',
      success_snackbar: false,
      recaptcha_token: '',
      recaptcha_valid: false,
      abLoading: false,
      singinLoading: false,
      regButtonLoading: false,
      show1: false,
  }),
  methods: {
  async recaptcha() {
    // (optional) Wait until recaptcha has been loaded.
    await this.$recaptchaLoaded()

    // Execute reCAPTCHA with action "login".
    this.recaptcha_token = await this.$recaptcha('login')

    // Do stuff with the received token.
    //console.log(this.recaptcha_token);
  },
  recaptchaValidation: async function () {
    await this.recaptcha()
    this.$http.post('/recaptcha', {
    token: this.recaptcha_token
    })
    .then((response) => {
      if (response.data['success'] == 'true') {
        this.recaptcha_valid = true
      }
      console.log(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  },
  AnonymousLogin: async function () {
    this.abLoading = true
    await this.recaptcha()

    this.$http.post('/recaptcha', {
    token: this.recaptcha_token
    })
    .then((response) => {
      if (response.data['success'] == 'true') {

        signInAnonymously(auth)
        .then(() => {
          this.User()
        })
        .catch((error) => {
          console.log(error);
        });
        this.abLoading = false
      } else {
        this.error_message = response.data['message'];
        this.error_snackbar = true;
      }
    })
    .catch(function (error) {
      console.log(error);
    });

  },
  Register: async function () {
    this.regButtonLoading = true;
    await this.recaptcha()

    this.$http.post('/recaptcha', {
    token: this.recaptcha_token
    })
    .then((response) => {
      if (response.data['success'] == 'true') {

        createUserWithEmailAndPassword(auth, this.login_user, this.password)
          .then((userCredential) => {
            // Signed in
            console.log(userCredential.user);
            this.User();
            this.success_message = 'Sikeres regisztráció!';
            this.success_snackbar = true;
          })
          .catch((error) => {
            this.error_snackbar = true;
            if (error.code == 'auth/email-already-in-use') {
              this.error_message = 'A megadott e-mail cím már használatban van!';
            } else {
              this.error_message = 'Hiba történt a regisztráció során!';
            }
            console.log('Hibakód: ' + error.code);
            console.log('Hibaüzenet: ' + error.message);
            // ..
          });
        this.regButtonLoading = false;
      } else {
        this.error_message = response.data['message'];
        this.error_snackbar = true;
      }
    })
    .catch(function (error) {
      console.log(error);
    });

  },
  Login: async function () {
    this.singinLoading = true;
    await this.recaptcha()

    this.$http.post('/recaptcha', {
    token: this.recaptcha_token
    })
    .then((response) => {
      if (response.data['success'] == 'true') {

        signInWithEmailAndPassword(auth, this.login_user, this.password)
          .then((userCredential) => {
            // Signed in
            console.log(userCredential.user);
            this.User();
            this.success_message = 'Sikeres bejelentkezés!';
            this.success_snackbar = true;
          })
          .catch((error) => {
            this.error_snackbar = true;
            if (error.code == 'auth/user-not-found') {
              this.error_message = 'Nincs ilyen felhasználó!';
            } else if (error.code == 'auth/wrong-password') {
              this.error_message = 'A megadott jelszó hibás!';
            } else if (error.code == 'auth/too-many-requests') {
              this.error_message = 'Túl sok bejelentkezési kísérlet! (Fiókja átmenetileg le lett tiltva!)';
            } else {
              this.error_message = 'Hiba történt a bejelentkezés során!';
            }
            console.log('Hibakód: ' + error.code);
            console.log('Hibaüzenet: ' + error.message);
          });
        this.singinLoading = false;
      } else {
        this.error_message = response.data['message'];
        this.error_snackbar = true;
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  },
  Logout: function () {
    signOut(auth)
    .then(() => {
      this.User()
    })
    .catch((error) => {
      console.log(error);
    });
  },
  User: function () {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user.uid;
        if (user.isAnonymous) {
          this.username = 'Név nélküli felhasználó';
        } else {
          this.username = user.email;
        }
      } else {
        console.log('Nincs bejelentkezett felhasználó!');
        this.user = null;
      }
    });
  }
 },
 mounted(){
  this.User()
  //this.recaptchaValidation()
 }
}
</script>
